import React from 'react'
import { NavBar } from './NavBar'
import { Banner } from './Banner'
import { Skills } from './Skills'
import { Projects } from './Projects'
import { Contact } from './Contact'
import { Footer } from './Footer'
import "../App.css"
import {Experiece }from './Experiece'

export const Allcomponets = () => {
  return (
    <div className="App">
    <NavBar />
    <Banner />
    {/* <Experiece/> */}
    <Skills />
    <Projects />
    <Contact />
    <Footer />
  </div>
  )
}
