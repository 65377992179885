import { Col } from "react-bootstrap";

export const ProjectCard = ({ title, description, tech }) => {
  return (
    <Col size={12} sm={6} md={4} >
      <div className="proj-imgbx" style={{height:'460px'}}>
       <h3 >{title}</h3>
       <h6 className="tap">Tap Here</h6>
        <div className="proj-txtx">
          <h4 >{title}</h4>
          <span >{description}</span><br/>
          <span>{tech}</span>
        </div>
          
      </div>
    </Col>
  )
}