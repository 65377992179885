import { Container, Row, Col, Tab, Nav } from "react-bootstrap";
import { ProjectCard } from "./ProjectCard";
import projImg1 from "../assets/img/project-img1.png";
import projImg2 from "../assets/img/project-img2.png";
import projImg3 from "../assets/img/project-img3.png";
import colorSharp2 from "../assets/img/color-sharp2.png";
import 'animate.css';
import TrackVisibility from 'react-on-screen';

export const Projects = () => {

  const projects = [
    {
      title: "ABODE",
      description: "Abode is a popular online platform where individuals can rent out their homes, apartments, or even sparerooms to travelers, allowing them to enjoy a more authentic and affordable stay while also offering hosts an opportunity to monetize their unused spaces. ",
      tech: "In this we use React js as front end, Node js as back end and MySQL as database"

    },

    {
      title: "Digital Card Hub (Online Shareable Business Card)",
      description: "It offers a convenient and eco friendly way for professionals to create, customize, and share their digital business cards. With the innovative nfc card tap feature,users can exchange contact information effortlessly  https://digitalcardhub.in/",

    },
    {
      title: "indiabuzz.co.in",
      description: "As a key member of the development team,I contributed to enhancing the user interface and user experience of the website.Leveraging my skills in HTML,CSS,JavaScript,and responsive design. I collaborated with designers and backend developers to create visually appealing and intuitive web pages.By incorporating best practices in frontend development. https://indiabuzz.co.in/",

    },
    {
      title: "Chandan Jewelers (Ecommerce Jewelry Shop)",
      description: "Chandan jewelers: ecommerce website developed using react,node.js,and mongo DB. Seamless online shopping experience for users to browse and purchase a wide range of jewelry products. Secure user authentication and authorization mechanisms for enhanced privacy and security. https://chandanjwellers.com/",

    },
    {
      title: "Bookpujanonline.com",
      description: "BookPujanOnline.com is a Web Application In Which A user can Choose Pujan Online and Book Panditji For that Particular Pujan. https://bookpujanonline.com/",

    },

    {
      title: "Sparshdentalgwl.com",
      description: "It is a Web Application in which a visitor can book appointment for a Dental Doctor. It is Used For a Clinic and improved user experience utilizing web accessibility. Reduced costs associated with missed appointments by 37%, improved patient appointment visits by 20%,increased customer satisfaction rating by 32%. https://sparshdentalgwl.com",

    },
    {
      title: "CLOTHIFY.COM",
      description: "Discover an exceptional online shopping experience at our E-Commerce Clothing Store. Browse a curated selection of the latest fashion trends and enjoy a user-friendly interface that ensures seamless navigation. Explore our diverse collection, find your style effortlessly, and redefine your wardrobe with just a few clicks. Elevate your shopping journey with secure transactions and timely deliveries, making fashion accessible at your fingertips.",

    },
    {
      title: "Ruchee Catering Service",
      description: "Catering service website built with React offers a seamless user experience. The website features a user-friendly inquiry form to request catering services, and integrates responsive design for smooth navigation across devices. This website efficiently presents the catering company's offerings and encourages potential clients to connect. https://rucheecateringservices.com/",

    },
    
   
  ];

  return (
    <section className="project" id="project">
      <Container>
        <Row>
          <Col size={12}>
            <TrackVisibility>
              {({ isVisible }) =>
                <div className={isVisible ? "animate__animated animate__fadeIn" : ""}>
                  <h2>Projects</h2>
                  <p>Each project reflects my proficiency in utilizing cutting-edge technologies, such as HTML5, CSS3, JavaScript, and responsive design, to develop intuitive interfaces and optimize performance across various devices. </p> <br />
                  <Tab.Container id="projects-tabs" defaultActiveKey="first">

                    <Tab.Content id="slideInUp" className={isVisible ? "animate__animated animate__slideInUp" : ""}>
                      <Tab.Pane eventKey="first">
                        <Row>
                          {
                            projects.map((project, index) => {
                              return (
                                <ProjectCard
                                  key={index}
                                  {...project}
                                />
                              )
                            })
                          }
                        </Row>
                      </Tab.Pane>
                     
                    </Tab.Content>
                  </Tab.Container>
                </div>}
            </TrackVisibility>
          </Col>
        </Row>
      </Container>
      <img className="background-image-right"  src={colorSharp2}></img>
    </section>
  )
}
