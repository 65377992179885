import React from 'react'
import { useNavigate } from 'react-router-dom'
import homeimage from '../assets/img/pexels-cottonbro-studio-5473302.jpg'
export default function Home() {
  const navigate=useNavigate();
  return (
    <div style={{display:'flex',justifyContent:'center',alignItems:'center',flexDirection:'column',height:'100vh',width:"100vw",backgroundImage:`url('${homeimage}')`,backgroundSize:'cover'}}>
     
        <div onClick={()=>window.open('https://digitalcardhub.in/#/abhirajshrivastava')} style={{display:'flex',flexDirection:'column',justifyContent:'center',alignItems:'center',cursor:"pointer",borderRadius:"50%",borderColor:"white",margin:15,height:165,width:165,marginTop:-100,fontSize:34,border:1,borderStyle:'solid'}}>
            Profile
            <div style={{display:'flex',justifyContent:'center',alignItems:'center',flexDirection:'column',fontSize:16}}>
              Tap here
            </div>
        </div>
        
        <div onClick={()=>navigate('/')} style={{display:'flex',flexDirection:'column',justifyContent:'center',alignItems:'center',cursor:"pointer",borderRadius:"50%",borderColor:"white",height:165,width:165,margin:10,fontSize:34,backgroundColor:'black' }}>
            Portfolio
            <div style={{display:'flex',justifyContent:'center',alignItems:'center',flexDirection:'column',fontSize:16}}>
              Tap here
            </div>
        </div>
    </div>
    
  )
}
