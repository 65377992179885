import logo from './logo.svg';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { BrowserRouter as Router,Routes,Route, HashRouter } from 'react-router-dom';
import Home from './components/Home';
import { Allcomponets } from './components/Allcomponets';
function App() {
  return (
    <div >
      <HashRouter>
        <Routes>
          <Route element={<Home/>} path='/card'/>
          <Route element={<Allcomponets/>} path='/'/>
        </Routes>
      </HashRouter>
    </div>
  );
}

export default App;
